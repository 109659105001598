import React from "react"
import loadable from "@loadable/component"

const Layout = loadable(() => import("@common/Layout/Layout"))
const SEO = loadable(() => import("@components/Common/SEO/SEO"))
const InternetServiceProvider = loadable(() => import("@components/ISP"))

const InternetServiceProviderPage = ({ path }) => {
  return (
    <>
      <SEO
        title="Custom software development for Internet Service Providers (ISPs)"
        titleTemplate="Impressit"
        description="Custom software solutions tailored for Internet Service Providers to improve network performance, automate processes, and enhance customer experience. Boost your ISP services today!"
        isNotIndexed={false}
        ownMeta={{
            name: "googlebot",
            content: "all"
        }}
        path={path}
      />
      <Layout path={path} isNoPadding>
        <InternetServiceProvider />
      </Layout>
    </>
  )
}
export default InternetServiceProviderPage
